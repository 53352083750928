<!--
 * @Author: lsw lsw_0524@163.com
 * @Date: 2024-03-28 15:30:48
 * @LastEditors: lsw lsw_0524@163.com
 * @LastEditTime: 2024-03-28 16:59:34
 * @FilePath: /yiyun_project/src/views/goods/supplier/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-main>
    <el-button style="margin-bottom: 12px" size="small" type="primary" @click="addSupplier">+ 添加</el-button>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="供应商编号：">
        <el-input v-model="form_search_list.supplier_no" size="small"></el-input>
      </el-form-item>
      <el-form-item label="供应商名称：">
        <el-input v-model="form_search_list.supplier_name" size="small"></el-input>
      </el-form-item>
      <el-form-item label="供应商代号：">
        <el-input v-model="form_search_list.code_name" size="small"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="getSupplierList(1)">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="form_search_list.list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
      <el-table-column prop="supplier_no" label="供应商编号" align="center"></el-table-column>
      <el-table-column prop="supplier_name" label="供应商名称" align="center"></el-table-column>
      <el-table-column prop="code_name" label="供应商代号" align="center"></el-table-column>
      <el-table-column label="创建时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button @click="editSupplier(row)" type="text" size="small">编辑</el-button>
          <el-button type="text" size="small" @click="deleteSupplier(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="form_search_list.total" :page="form_search_list.page" :pageNum="form_search_list.rows" @updatePageNum="updateData"></Paging>
    <el-dialog :title="mode == 1 ? '添加供应商' : '编辑供应商'" :visible.sync="show_dialog" width="500px">
      <el-form :model="data_form" label-width="100px">
        <el-form-item label="供应商编号">
          <el-input v-model="data_form.supplier_no"></el-input>
        </el-form-item>
        <el-form-item label="供应商名称">
          <el-input v-model="data_form.supplier_name"></el-input>
        </el-form-item>
        <el-form-item label="供应商代号">
          <el-input v-model="data_form.code_name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show_dialog = false">取 消</el-button>
        <el-button type="primary" @click="sureSupplier">确 定</el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      getDateformat,
      // 1-添加 2-移动
      mode: 1,
      show_dialog: false,
      data_form: {
        supplier_no: '',
        supplier_name: '',
        code_name: '',
      },
      form_search_list: {
        page: 1,
        rows: 10,
        list: [],
        total: 0,
        supplier_no: '',
        supplier_name: '',
        code_name: '',
      },
    };
  },
  created() {
    this.getSupplierList();
  },
  methods: {
    clearSearch() {
      this.form_search_list = {
        page: 1,
        rows: 10,
        list: [],
        total: 0,
        supplier_no: '',
        supplier_name: '',
        code_name: '',
      };
      this.getSupplierList();
    },
    deleteSupplier(data) {
      this.$confirm('此操作将永久删除该供应商, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.mathematical.supplierDel, {
            id: data.id,
          });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.getSupplierList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    updateData(val, status) {
      if (status == 0) {
        this.form_search_list.rows = val;
      } else {
        this.form_search_list.page = val;
      }
      this.getSupplierList();
    },
    addSupplier() {
      this.mode = 1;
      this.data_form = {
        supplier_no: '',
        supplier_name: '',
        code_name: '',
      };
      this.show_dialog = true;
    },
    editSupplier(data) {
      this.mode = 2;
      this.data_form = {
        supplier_no: data.supplier_no,
        supplier_name: data.supplier_name,
        code_name: data.code_name,
        id: data.id,
      };
      this.show_dialog = true;
    },
    sureSupplier() {
      for (const key in this.data_form) {
        if (this.data_form[key] === '') {
          this.$message.warning('请将数据填写完整');
          break;
        }
      }
      let obj = JSON.parse(JSON.stringify(this.data_form));
      let url = this.$api.mathematical.supplierAdd;
      if (this.mode == 2) url = this.$api.mathematical.supplierEdit;
      this.$axios.post(url, obj).then(res => {
        if (res.code == 0) {
          this.$message.success('操作成功');
          this.getSupplierList();
          this.show_dialog = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getSupplierList(style) {
      if (style) {
        this.form_search_list.page = 1;
      }
      let obj = {
        page: this.form_search_list.page,
        rows: this.form_search_list.rows,
      };
      if (this.form_search_list.supplier_no !== '') obj.supplier_no = this.form_search_list.supplier_no;
      if (this.form_search_list.supplier_name !== '') obj.supplier_name = this.form_search_list.supplier_name;
      if (this.form_search_list.code_name !== '') obj.code_name = this.form_search_list.code_name;
      this.$axios.post(this.$api.mathematical.supplierList, obj).then(res => {
        if (res.code == 0) {
          this.form_search_list.list = res.result.list;
          this.form_search_list.total = res.result.total_number;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  background-color: #fff;
}
</style>
